import { NavLink } from 'react-router-dom';

const Sidebar = ({ children }) => {
    const menuItem = [
        {
            path: "/",
            name: "Tasks",

        },
        {
            path: "/activity",
            name: "Activity",

        },
        {
            path: "/payments",
            name: "Payments",
        },
        {
            path: "/charges",
            name: "Charges",

        },
        {
            path: "/rate",
            name: "Rate",

        },
        {
            path: "/users",
            name: "Users",

        },
        {
            path: "/profile",
            name: "Profile",

        },


    ];

    return (
        <>
            <div className="container-sidebar">
                <div className="sidebar">
                    <div className="topMargin">
                        {menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className="link-active">
                                <div className="link_text">{item.name}</div>
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className="main-content">
                    {children}
                </div>
            </div>
        </>
    );
};

export default Sidebar;

