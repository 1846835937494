import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialValue = []) => {
  const storedValue = JSON.parse(localStorage.getItem(key)) || initialValue;
  const [value, setValue] = useState(storedValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  const addObject = (object) => {
    // Check for duplicates before adding the object
    if (!value.some((item) => isEqual(item, object))) {
      setValue((prevValue) => [...prevValue, object]);
    }
  };

  const removeObject = (index) => {
    setValue((prevValue) => {
      const updatedValue = [...prevValue];
      updatedValue.splice(index, 1);
      return updatedValue;
    });
  };

  const clearArray = () => {
    setValue([]);
  };

  // Function to check if two objects are equal
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  return [value, addObject, removeObject, clearArray];
};

export default useLocalStorage;


// export const handleRemoveObject = (index) => {
//     removeObject(index);
//   };
  
// export const handleClearArray = () => {
//     clearArray();
//   };