import { Link, useNavigate } from "react-router-dom";
import { handleLogout } from './AuthUtils';


const Logout = ({ isLogin, setIsLogin }) => {
  const navigate = useNavigate();
  const storedData = localStorage.getItem('userObjectArray');
  const username = storedData ? JSON.parse(storedData)[0]?.name : null


  const onButtonClick = () => {
    if (isLogin) {
      handleLogout()
      setIsLogin(false)
    } else {
      navigate('/login')
    }
  }
  return (
    <>
      <div className="text-4xl mx-5 mt-5 mb-2">Welcone Mr. {username || 'Guest'}</div>
      <Link className="text-4xl text-blue-500 hover:underline px-3 py-1 mx-5 my-10" onClick={onButtonClick}>Logout</Link>
    </>
  )
}

export default Logout