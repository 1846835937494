import React, { useState } from "react";
import { BsSearch } from 'react-icons/bs';

const Search = ({ data, setFilterData }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    const result = data.filter((state) => {
      const userid = state.userid || "";
      const taskid = state.taskid || "";
      const id = state.id ? state.id.toString() : "";
      const projectid = state.projectid || "";

      if (searchTerm === "") {
        return true;
      }

      const searchTerms = searchTerm.split(/\s*(?=\b(?:taskid|userid|projectid):)/);

      return searchTerms.every((term) => {
        const trimmedTerm = term.trim().toLowerCase();

        if (trimmedTerm.startsWith("id:")) {
          const searchValues = trimmedTerm.slice(3).trim().split(",");
          return searchValues.includes(id);
        }

        if (trimmedTerm.startsWith("taskid:")) {
          const searchValue = trimmedTerm.slice(7).trim();
          return taskid.toLowerCase().includes(searchValue.toLowerCase());
        }

        if (trimmedTerm.startsWith("userid:")) {
          const searchValue = trimmedTerm.slice(7).trim();
          return userid.toLowerCase().includes(searchValue.toLowerCase());
        }

        if (trimmedTerm.startsWith("projectid:")) {
          const searchValue = trimmedTerm.slice(10).trim();
          return projectid.toLowerCase().includes(searchValue.toLowerCase());
        }

        return (
          userid.includes(trimmedTerm) ||
          taskid.includes(trimmedTerm) ||
          trimmedTerm.includes(trimmedTerm) ||
          projectid.toLowerCase().includes(trimmedTerm)
        );
      });
    });
    setFilterData(result);
  };



  return (
    <>
      <div className="d-flex">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          style={{
            borderRadius: "40px 0 0 40px",
            width: "440px",
            height: "40px"
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          
        />
        
        <button
          type="button"
          className="btn btn-primary"
          style={{ width: "64px", height: "40px", borderRadius: "0 40px 40px 0" }}
          onClick={handleSearch}
        >
          <BsSearch />
        </button>
      </div>
    </>
  );
};

export default Search;
