import { useState, useMemo } from "react";
import { BsArrowDownUp } from "react-icons/bs";
const ProjectsTable = ({ currentPosts }) => {

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const sortedData = useMemo(() => {
    const sortedArray = [...currentPosts];

    if (sortColumn !== "") {
      sortedArray.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "lastmodified") {
          // Convert timestamps to Date objects for comparison
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();

          if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
          if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        } else {
          if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
          if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        }
      });
    }

    return sortedArray;
  }, [currentPosts, sortColumn, sortDirection]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };


  return (
    <>
      <table id="" className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center" style={{ width: "2%" }} scope="col" onClick={() => handleSort("id")}>
              id<BsArrowDownUp />
            </th>
            <th className="text-center" style={{ width: "10%" }} scope="col" onClick={() => handleSort("projectid")}>
            projectid{" "}<BsArrowDownUp />
            </th>
            <th className="text-center" style={{ width: "6%" }} scope="col" onClick={() => handleSort("new_projectid")}>
            new_projectid{" "}<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "8%" }} scope="col" onClick={() => handleSort("projectname")}>
            projectname{" "}<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("clientid")}>
            clientid{" "}<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("startdate")}>
            startdate{" "}<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "5%" }} scope="col" onClick={() => handleSort("status")}>
            status{" "}<BsArrowDownUp />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((myData, index) => (
            <tr className="" key={index}>
              <th className="text-center" scope="row">{myData.id}</th>
              <td>{myData.projectid}</td>
              <td>{myData.new_projectid}</td>
              <td>{myData.projectname}</td>
              <td>{myData.clientid}</td>
              <td>{myData.startdate}</td>
              <td>{myData.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProjectsTable;