import { useState, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalContext';
import Pagination from "../../components/Pagination";
import Search from '../../components/Search';
import RateTable from './RateTable';

const Rate = () => {
    const { ratesData, ratesFilterData, setRatesFilterData } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    // Make sure filterData is defined and not null
    const filteredData = ratesFilterData || [];

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // Slice the filteredData array
  const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filteredData.length / postsPerPage);

    return (
        <>
            <div className="container">
                <div className='mb-1 mt-2'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Search data={ratesData} setFilterData={setRatesFilterData} />
                        </div>
                    </div>
                </div>
                <div >
                    <RateTable currentPosts={currentPosts} />
                </div>
            </div>
            <div className='text-md-right'>
                <div className='d-flex justify-content-between'>
                    <div className='ml-5'>
                        <label htmlFor="">Jump to</label>
                        <select
                            className='mx-2'
                            style={{ width: "50px", height: "30px" }}
                            value={postsPerPage}
                            onChange={(e) => {
                                setPostsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <span>Page {currentPage} of Pages {howManyPages}</span>
                    </div>
                    <div className='mx-5 mb-5'>
                        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rate;