import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Tasks from './pages/tasks/Tasks';
import Activity from './pages/activity/Activity';
import Payments from './pages/payments/Payments';
import GlobalProvider from './context/GlobalProvider';
import Charges from './pages/charges/Charges';
import Rate from './pages/rate/Rate';
import Users from './pages/users/Users';
import Projects from './pages/projects/Projects';
import Login from './login/Login'
import Register from './login/Register'
import { startTokenExpirationCheck } from './login/AuthUtils';
import InactivityLogout from './login/InactivityLogout';
import Logout from './login/Logout';


const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null
    const username = storedData ? JSON.parse(storedData)[0]?.username : null
    console.log(username);

    if (authTokenAccess && authTokenAccess.length > 0) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  useEffect(() => {
    startTokenExpirationCheck();
  }, []);


  return (
    <GlobalProvider>
      <BrowserRouter>
        {isLogin ? (
          <Sidebar>
            <Routes>
              <>
                <Route path="/" element={<Tasks />} />
                <Route path="/activity" element={<Activity />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/charges" element={<Charges />} />
                <Route path="/rate" element={<Rate />} />
                <Route path="/users" element={<Users />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Logout isLogin={isLogin} setIsLogin={setIsLogin} />} />
              </>
            </Routes>
          </Sidebar>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>

        )}
         <InactivityLogout />
      </BrowserRouter>
    </GlobalProvider>
  );
};

export default App;
