import { useState, useMemo } from "react";
import "./Tasks.css"
import { BsArrowDownUp } from "react-icons/bs";

const Table = ({ currentPosts, handleCheckboxChange, selectedIds, selectAll, handleSelectAll }) => {

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const sortedData = useMemo(() => {
    const sortedArray = [...currentPosts];

    if (sortColumn !== "") {
      sortedArray.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "lastmodified") {
          // Convert timestamps to Date objects for comparison
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();

          if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
          if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        } else {
          if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
          if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        }
      });
    }

    return sortedArray;
  }, [currentPosts, sortColumn, sortDirection]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };


  return (
    <>
      <table id="" className="table table-bordered">
        <thead className="">
          <tr>
            <th className="text-center" scope="row" style={{ width: "3%" }} >
              <input
                type="checkbox"
                checked={
                  currentPosts.length > 0 &&
                  currentPosts.every(item => selectedIds.includes(item.id))
                }
                onChange={handleSelectAll}
              />
            </th>
            <th className="text-center" style={{ width: "5%" }} scope="col" onClick={() => handleSort("id")}>
              id<BsArrowDownUp />
            </th>
            <th className="text-center" style={{ width: "15%" }} scope="col" onClick={() => handleSort("taskid")}>
              taskid<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "15%" }} scope="col" onClick={() => handleSort("filename")}>
              filename<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "15%" }} scope="col" onClick={() => handleSort("projectid")}>
              projectid<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "15%" }} scope="col" onClick={() => handleSort("user")}>
              userid<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "12%" }} scope="col" onClick={() => handleSort("wordcounts")}>
              wordcounts<BsArrowDownUp />
            </th>
            <th className="" style={{ width: "8%" }} scope="col" onClick={() => handleSort("status")}>
              status<BsArrowDownUp />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((myData, index) => (
            <tr key={myData.id} style={{ backgroundColor: selectedIds.includes(myData.id) ? '#c1d3f0' : '' }}>
              <td className="">
                <div className="custom-control custom-checkbox text-center"
                  style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`customCheck${myData.id}`}
                    checked={selectedIds.includes(myData.id) || selectAll}
                    onChange={() => handleCheckboxChange(myData.id)}
                  />
                </div>
              </td>
              <th className="text-center" scope="row">{myData.id}</th>
              <td>{myData.taskid}</td>
              <td>{myData.filename}</td>
              <td>{myData.projectid}</td>
              <td>{myData.userid}</td>
              <td>{myData.wordcounts}</td>
              <td>{myData.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;