import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconsSubmit from '../components/styled/IconsSubmit';
import { inputstyle } from '../GlobalStyles';

const Register = () => {
  const navigate = useNavigate();
  const { API_URL, showToast } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    mobileNumber: '',
    emailAddress: '',
    password1: '',
    password2: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when the user starts typing
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const errors = {};

    // Name validation
    if (formData.Name.trim() === '') {
      errors.Name = 'This field is required';
    }

    // Mobile number validation
    const mobileNumberRegex = /^[0-9]{10}$/;
    if (!mobileNumberRegex.test(formData.mobileNumber)) {
      errors.mobileNumber = 'Enter a valid 10-digit mobile number';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.emailAddress)) {
      errors.emailAddress = 'Enter a valid email address';
    }

    // Password validation (modify as needed)
    if (formData.password1.trim() === '') {
      errors.password1 = 'This field is required';
    } else {
      const password1Regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%.]{8,}$/;

      if (!password1Regex.test(formData.password1)) {
        errors.password1 = 'Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @#$%.';
      }
    }

    // Password validation (modify as needed)
    if (formData.password2.trim() === '') {
      errors.password2 = 'This field is required';
    } else {
      const password2Regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%.]{8,}$/;

      if (!password2Regex.test(formData.password2)) {
        errors.password2 = 'Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @#$%.';
      }
    }

    // Check if passwords match
    if (formData.password1 !== formData.password2) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true)
      // showToast('pending', 'Processing, Please Wait...', 'top', 10);
      try {
        const response = await axios.post(`${API_URL}/userAuthRegister`, {
          name: formData.Name,
          mobile: formData.mobileNumber,
          username: formData.emailAddress,
          password: formData.password1,
        });

        const responseData = await response.data;

        if (responseData.success && response.status === 200) {
          // showToast('success', responseData.success, 'top', 10);
          navigate('/login')
        } else if (responseData.message) {
          // showToast('failure', responseData.message, 'top', 10);
          console.error('Registration failed.');
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle network error or other issues
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="flex justify-center mt-1 px-5">
        <div className="max-w-md p-5 my-form rounded shadow-lg">
          <h1 className="text-2xl text-gray-100 text-center font-semibold mb-5">Register</h1>
          <form onSubmit={handleSubmit}>
            <label className='text-xl text-gray-100' htmlFor="name">Name</label>
            <input
              className={`${inputstyle} ${formErrors.Name ? 'w-full border-red-500' : ''
                }`}
              type="text"
              placeholder="Name"
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
            />
            {formErrors.Name && (
              <p className="text-red-500 text-xs italic">{formErrors.Name}</p>
            )}

            <label className='text-xl text-gray-100' htmlFor="mobileNumber">Mobile No.</label>
            <input
              className={`${inputstyle} ${formErrors.mobileNumber ? 'w-full border-red-500' : ''
                }`}
              type="text"
              placeholder="Mobile Number"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
            />
            {formErrors.mobileNumber && (
              <p className="text-red-500 text-xs italic">{formErrors.mobileNumber}</p>
            )}

            <label className='text-xl text-gray-100' htmlFor="emailAddress">Email ID</label>
            <input
              className={`${inputstyle} ${formErrors.emailAddress ? 'w-full border-red-500' : ''
                }`}
              type="text"
              placeholder="Email ID"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
            />
            {formErrors.emailAddress && (
              <p className="text-red-500 text-xs italic">{formErrors.emailAddress}</p>
            )}

            <label className='text-xl text-gray-100' htmlFor="password">Password</label>
            <input
              className={`${inputstyle} ${formErrors.password1 ? 'w-full border-red-500' : ''
                }`}
              type="password"
              placeholder="Password"
              name="password1"
              value={formData.password1}
              onChange={handleInputChange}
            />
            {formErrors.password2 && (
              <p className="text-red-500 text-xs italic">{formErrors.password1}</p>
            )}

            <label className='text-xl text-gray-100' htmlFor="confirmedPassword">Confirmed Password</label>
            <input
              className={`${inputstyle} ${formErrors.password2 ? 'w-full border-red-500' : ''
                }`}
              type="password"
              placeholder="Confirmed Password"
              name="password2"
              value={formData.password2}
              onChange={handleInputChange}
            />
            {formErrors.password2 && (
              <p className="text-red-500 text-xs italic">{formErrors.password2}</p>
            )}

            {formErrors.confirmPassword && (
              <p className="text-red-500 text-xs italic">{formErrors.confirmPassword}</p>
            )}

            <div className="text-center">
              <button
                className="w-24 bg-blue-600 hover:bg-blue-700 text-white py-2 rounded text-sm tracking-wide"
                type="submit"
              >
                {isLoading ? <IconsSubmit /> : 'Register'}
              </button>
            </div>
            <div className="text-sm text-slate-500 text-center mt-4">
              Have an account?{' '}
              <Link className="text-red-600 hover:underline hover:underline-offset-4" to="/login">
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
