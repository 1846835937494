import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { inputstyle } from '../GlobalStyles';
import { GlobalContext } from '../context/GlobalContext';
import axios from 'axios';
import useLocalStorage from "../hooks/useLocalStorage"
import IconsSubmit from '../components/styled/IconsSubmit';

const Login = () => {
  const { API_URL, showToast } = useContext(GlobalContext);
  // eslint-disable-next-line
  const [userObjectArray, addObject, removeObject, clearArray] = useLocalStorage('userObjectArray', []);

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [passwordError, setPasswordError] = useState('');
  // const [emailError, setEmailError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (password.trim() === '') {
      errors.password = 'Password is required'
    } else {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%.]{8,}$/;
      if (!passwordRegex.test(password)) {
        errors.password = 'Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @#$%.'
      }
    }

    if (emailAddress.trim() === '') {
      errors.emailAddress = 'Email is required'
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(emailAddress)) {
        errors.emailAddress = 'Enter a valid email address.'
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;

  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true)
      // showToast('pending', 'Processing, Please Wait...', 'top', 10);
      try {
        const response = await axios.post(`${API_URL}/userAuthLogin`, {
          username: emailAddress,
          password: password,
        });

        const data = await response.data

        console.log("username", data)
        if (data.auth) {
          addObject(data);
          // showToast('success', 'Login successful!', 'top', 10);
          const expirationTime = new Date().getTime() + 10 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime.toString());
          window.location.replace('/');
        } else if (response) {
          // showToast('error', response.data.message, 'top', 10);
          console.error('Authentication failed.');
        }
      } catch (error) {
        // showToast('failure', error.message, 'top', 10);
        console.log("Error:", error);
      } finally { setIsLoading(false) }
    }
  };

  // const handleSuccess = () => {
  //   showToast('success', 'Operation successful!', 'top', 10);
  // };


  return (
    <>
      <div className="flex justify-center mt-1 px-5">
        <div className="max-w-md p-5 my-form rounded shadow-lg">
          <h1 className="text-2xl text-gray-100 text-center font-semibold mb-5">Login</h1>
          <form>
            <label className='text-xl text-gray-100' htmlFor="emailAddress">Email ID</label>
            <input
              className={`${inputstyle} ${formErrors.emailAddress ? 'w-full border-red-500 mb-0' : ''}`}
              type="emailAddress"
              placeholder="Email ID"
              value={emailAddress}
              onChange={handleEmailChange}
            />
            {formErrors.emailAddress && <p className="text-sm text-red-500 mb-1">{formErrors.emailAddress}</p>}

            <label className='text-xl text-gray-100' htmlFor="password">Password</label>
            <input
              className={`${inputstyle} ${formErrors.password ? 'w-full border-red-500 mb-0' : ''}`}
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {formErrors.password && <p className="text-sm text-red-500 mb-1">{formErrors.password}</p>}

            <div className="flex justify-between font-semibold text-sm mb-4">
              <label className="flex text-slate-500 hover:text-slate-600 cursor-pointer">
                <input className="mr-1" type="checkbox" />
                <span className='text-gray-100'>Remember Me</span>
              </label>
              <Link className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4" to="/forgotPassword">
                Forgot Password?
              </Link>
            </div>
            <div className="text-center">
              <button
                className="w-36 text-3xl bg-blue-600 hover:bg-blue-100 hover:text-gray-900 text-white py-1 px-3 rounded"
                type="button"
                onClick={handleLogin}
              >
                <span className='flex-col text-center'> {isLoading ? <IconsSubmit /> : 'Login'} </span>
              </button>
            </div>
            <div className="text-sm text-slate-500 text-center mt-4">
              Don't have an account?{' '}
              <Link className="text-red-600 hover:underline hover:underline-offset-4" to="/register">
                Register
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
