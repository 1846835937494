import { useEffect, useState } from 'react';
import { handleLogout } from './AuthUtils';

const InactivityLogout = () => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setIsActive(false);
        handleLogout(); 
      }, 15 * 60 * 1000); // Timeout set to 15 minutes
    };

    const handleUserActivity = () => {
      if (!isActive) {
        setIsActive(true);
      }
      resetTimer();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        handleUserActivity();
      }
    };

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keypress', handleUserActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetTimer();

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keypress', handleUserActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange); // Remove instead of add
      clearTimeout(timeoutId);
    };
  }, [isActive]);

  return null;
};

export default InactivityLogout;
