export const checkTokenExpiration = () => {
  const authToken = localStorage.getItem('auth');
  const expirationTime = localStorage.getItem('expirationTime');

  if (authToken && expirationTime) {
    const currentTime = new Date().getTime();
    if (currentTime > parseInt(expirationTime, 10)) {
      handleLogout();
    }
  } else {
    handleLogout();
  }
};


export const startTokenExpirationCheck = () => {
  const expirationCheckInterval = 60 * 60 * 1000;
  setInterval(() => {
    checkTokenExpiration();
  }, expirationCheckInterval);
};

export const handleLogout = () => {
  localStorage.removeItem('userObjectArray');
  localStorage.removeItem('expirationTime');
  window.location.replace('/login');
};

