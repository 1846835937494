import { useState } from "react";
import Editable from "../../Editable/Editable";

const Payments = () => {
    // eslint-disable-next-line 
    const [data, setData] = useState([])
    const columns = [
        { key: 'id', title: 'ID', className: 'w-5 px-2 border' },
        { key: 'name', title: 'Name', className: 'w-10 px-2 border' },
        { key: 'amount', title: 'Amount', className: 'w-10 px-2 border' },
        { key: 'balance', title: 'Balance', className: 'w-10 px-2 border' },
    ];

    //   const data = [
    //     { id: 1, name: 'John', age: 25, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },
    //     { id: 2, name: 'Jane', age: 30, regNum:122345 },

    //   ];

    return (
        <>
            <h1>Payments page</h1>
            <div className=" px-2">
                <Editable columns={columns} data={data} />
            </div>
            
        </>
    );
};

export default Payments;